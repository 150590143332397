import React, { useState, useEffect } from "react";

const PomodoroApp = () => {
  const motivationalQuotes = [
    "Small daily improvements lead to stunning results over time.",
    "Progress is the result of consistent effort and persistence.",
    "Celebrate each task you complete, as it's a step towards your goals.",
    "Rewards make the hard work feel like an accomplishment, not a chore.",
    "Every small task completed builds your future success.",
    "Success is the sum of small efforts, repeated day in and day out.",
    "Consistency is the key to long-term growth.",
    "Reward yourself for the journey, not just the destination.",
    "Hard work pays off, especially when there's a reward waiting for you.",
    "Big goals are achieved through small, consistent actions.",
  ];

  const [cards, setCards] = useState([
    {
      task: "Read emails",
      reward: "Drink Coffee",
      minutes: 20,
      timeLeft: 1200,
      isRunning: false,
      timerId: null,
      showCongrats: false,
      quote:
        motivationalQuotes[
          Math.floor(Math.random() * motivationalQuotes.length)
        ],
    },
    {
      task: "Write report",
      reward: "Watch TV",
      minutes: 30,
      timeLeft: 1800,
      isRunning: false,
      timerId: null,
      showCongrats: false,
      quote:
        motivationalQuotes[
          Math.floor(Math.random() * motivationalQuotes.length)
        ],
    },
    {
      task: "Workout",
      reward: "Play video games",
      minutes: 15,
      timeLeft: 900,
      isRunning: false,
      timerId: null,
      showCongrats: false,
      quote:
        motivationalQuotes[
          Math.floor(Math.random() * motivationalQuotes.length)
        ],
    },
  ]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // Request Notification permission on load
    if ("Notification" in window) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission !== "granted") {
            console.log("Permission for notifications was denied");
          }
        });
      }
    } else {
      console.log("This browser does not support desktop notifications.");
    }
  }, []);

  const sendNotification = (task, reward) => {
    if ("Notification" in window && Notification.permission === "granted") {
      new Notification(`Task Complete: ${task}`, {
        body: `Now enjoy your reward: ${reward}`,
        icon: "/path/to/icon.png", // Optional: Replace with an actual icon path
        vibrate: [200, 100, 200], // Optional: Vibrate pattern for mobile devices
      });
    } else {
      console.log("Notifications are not supported or permission was denied");
    }
  };

  const addCard = () => {
    setCards([
      ...cards,
      {
        task: "New Task",
        reward: "New Reward",
        minutes: 20,
        timeLeft: 1200,
        isRunning: false,
        timerId: null,
        showCongrats: false,
        quote:
          motivationalQuotes[
            Math.floor(Math.random() * motivationalQuotes.length)
          ], // Add a random quote
      },
    ]);
  };

  const removeCard = (index) => {
    if (cards[index].timerId) {
      clearInterval(cards[index].timerId); // Stop timer when deleting the card
    }
    const updatedCards = cards.filter((_, i) => i !== index);
    setCards(updatedCards);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const startTimer = (index) => {
    const updatedCards = [...cards];
    const card = updatedCards[index];

    if (card.isRunning) return; // Prevent multiple intervals on the same timer

    card.isRunning = true;

    const timerId = setInterval(() => {
      setCards((prevCards) => {
        const newCards = [...prevCards];
        const newCard = { ...newCards[index] };

        if (newCard.timeLeft > 0) {
          newCard.timeLeft -= 1;
        } else {
          clearInterval(newCard.timerId);
          newCard.isRunning = false;
          newCard.showCongrats = true; // Show the congratulations window

          playAlarm(); // Play alarm sound

          // Send notification after task completion
          sendNotification(newCard.task, newCard.reward);
        }

        newCards[index] = newCard;
        return newCards;
      });
    }, 1000);

    card.timerId = timerId;
    setCards(updatedCards);
  };

  const resetTimer = (index) => {
    const updatedCards = [...cards];
    const card = updatedCards[index];

    clearInterval(card.timerId);
    card.isRunning = false;
    card.timeLeft = card.minutes * 60;
    card.timerId = null;
    card.showCongrats = false;

    setCards(updatedCards);
  };

  const closeCongratsWindow = (index) => {
    const updatedCards = [...cards];
    updatedCards[index].showCongrats = false;
    setCards(updatedCards);
  };

  const formatTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const playAlarm = () => {
    const alarmSound = new Audio("piano.wav");
    alarmSound.play();
  };

  const handleMinutesChange = (index, value) => {
    const updatedCards = [...cards];
    const newMinutes = parseInt(value);
    if (!isNaN(newMinutes) && newMinutes >= 1) {
      updatedCards[index].minutes = newMinutes;
      updatedCards[index].timeLeft = newMinutes * 60;
    }
    setCards(updatedCards);
  };

  const handleMinutesClick = (index) => {
    const updatedCards = [...cards];
    updatedCards[index].minutes = "";
    setCards(updatedCards);
  };

  return (
    <div className="pomodoro-app">
      {/* Navbar with anchor links */}
      <nav className="navbar">
        <div className="navbar-menu" onClick={toggleMenu}>
          <span className="hamburger">&#9776;</span>
        </div>
        <div className={`navbar-links ${menuOpen ? "open" : ""}`}>
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
        </div>
      </nav>
      <br></br>
      <div className="card-container">
        {cards.map((card, index) => (
          <div className="card" key={index}>
            {!card.showCongrats ? (
              <>
                <h1>{card.reward}</h1> {/* Dynamic heading based on REWARD */}
                <p>{card.quote}</p> {/* Display random motivational quote */}
                <label>TASK</label>
                <input
                  type="text"
                  value={card.task}
                  onChange={(e) => {
                    const updatedCards = [...cards];
                    updatedCards[index].task = e.target.value;
                    setCards(updatedCards);
                  }}
                />
                <label>REWARD</label>
                <input
                  type="text"
                  value={card.reward}
                  onChange={(e) => {
                    const updatedCards = [...cards];
                    updatedCards[index].reward = e.target.value;
                    setCards(updatedCards);
                  }}
                />
                <label>MINUTES</label>
                <div className="time-control">
                  <button
                    onClick={() => {
                      const updatedCards = [...cards];
                      updatedCards[index].minutes = Math.max(
                        1,
                        updatedCards[index].minutes - 1
                      );
                      updatedCards[index].timeLeft =
                        updatedCards[index].minutes * 60;
                      setCards(updatedCards);
                    }}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={card.minutes}
                    onClick={() => handleMinutesClick(index)} // Clear minutes on click
                    onChange={(e) => handleMinutesChange(index, e.target.value)}
                  />
                  <button
                    onClick={() => {
                      const updatedCards = [...cards];
                      updatedCards[index].minutes += 1;
                      updatedCards[index].timeLeft =
                        updatedCards[index].minutes * 60;
                      setCards(updatedCards);
                    }}
                  >
                    +
                  </button>
                </div>
                <div className="timer">
                  <span>{formatTime(card.timeLeft)}</span>
                </div>
                <button
                  className="start-button"
                  onClick={() => startTimer(index)}
                >
                  START NOW
                </button>
                <button
                  className="reset-button"
                  onClick={() => resetTimer(index)}
                >
                  RESET
                </button>
                <button
                  className="remove-button"
                  onClick={() => removeCard(index)}
                >
                  DELETE
                </button>
              </>
            ) : (
              <div className="congrats">
                <button
                  className="close-button"
                  onClick={() => closeCongratsWindow(index)}
                >
                  X
                </button>
                <h1>Congratulations!</h1>
                <p>
                  You've completed the task "<strong>{card.task}</strong>"!
                  <br /> Now enjoy your reward: "<strong>{card.reward}</strong>
                  ".
                </p>
              </div>
            )}
          </div>
        ))}

        {/* "Add New Task" Window Styled as a Card */}
        <div className="card add-task-window">
          <button className="add-button" onClick={addCard}>
            ADD NEW TASK
          </button>
        </div>
      </div>

      {/* Home Section */}
      <br></br>
      {/* How To Use App Section */}
      <div className="section">
        <h1>How to Use the App</h1>
        <br></br>
        <br></br>
        <p
          style={{
            fontSize: "18px",
            color: "#7F8C8D",
            textAlign: "justify",
            marginBottom: "20px",
          }}
        >
          Welcome to the Purefocus App! This tool is designed to help you focus
          on your tasks by breaking them into timed intervals (called
          "Pomodoros") and rewarding yourself once you've completed them.
        </p>
        <br></br>
        <p
          style={{
            fontSize: "18px",
            color: "#7F8C8D",
            textAlign: "justify",
            marginBottom: "20px",
          }}
        >
          <strong> 1. Set Your Task:</strong> In each task window, type in the
          task you want to focus on. You can create as many tasks as you want by
          clicking the "Add New Task" button.<br></br>
          <br></br>
          2. <strong>Set Your Reward: </strong>Along with the task, set a reward
          you can enjoy after the task is complete.<br></br>
          <br></br>
          3. <strong>Set the Timer: </strong>Use the minus (-) and plus (+)
          buttons, or manually input the time you wish to allocate for each
          task. The timer starts counting down as soon as you press "Start Now".
          <br></br>
          <br></br>
          4. <strong>Complete the Task:</strong> When the timer runs out, a
          congratulatory message will appear, and you’ll be reminded to enjoy
          your reward.
          <br></br>
          <br></br>
          5. <strong>Reset or Delete Tasks:</strong> If you want to restart a
          task or remove it, simply use the "Reset" or "Delete" buttons provided
          for each task window.
        </p>
        <br></br>
        <br></br>

        <h3>Feedback and Suggestions</h3>
        <br></br>
        <p
          style={{
            fontSize: "18px",
            color: "#7F8C8D",
            textAlign: "justify",
            marginBottom: "20px",
          }}
        >
          This app is currently in its{" "}
          <strong>Minimum Viable Product (MVP)</strong> phase, which means we’re
          still working on adding more features and improving functionality.
          Your feedback is valuable to us, and we encourage you to share any
          suggestions or ideas for additional features.
        </p>
        <p
          style={{
            fontSize: "18px",
            color: "#7F8C8D",
            textAlign: "justify",
            marginBottom: "20px",
          }}
        >
          Feel free to reach out to us via email at{" "}
          <strong>info@purefocus.dev</strong> with your thoughts and ideas.
          Together, we can create a more focused and rewarding experience!
        </p>
        <br></br>
        <br></br>
      </div>

      {/* About Section */}
      <div id="about" className="section">
        <br />
        <h3>About Purefocus App</h3>
        <div
          className="motivation-section"
          style={{
            fontFamily: "Roboto, sans-serif",
            color: "#2C3E50",
            maxWidth: "800px",
            margin: "0 auto",
            lineHeight: "1.6",
          }}
        >
          <h1
            style={{
              fontFamily: "Playfair Display, serif",
              fontWeight: 700,
              fontSize: "36px",
              marginBottom: "15px",
              color: "#2C3E50",
              textAlign: "center",
            }}
          >
            Small Rewards as a Tool to Achieve Bigger Goals
          </h1>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            Using small rewards to achieve bigger goals is an effective
            psychological tool for boosting focus, enhancing motivation, and
            encouraging task initiation. When we break down our larger
            objectives into smaller tasks and pair them with immediate,
            attainable rewards, we leverage a concept called
            <strong> chunking</strong>. This method makes overwhelming goals
            more manageable, and the anticipation of a reward boosts dopamine
            levels in the brain, increasing focus and drive. The reward doesn’t
            have to be grand; even small incentives can trigger a sense of
            accomplishment, which can snowball into sustained productivity and
            progress toward long-term goals.
          </p>

          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            One of the key challenges people face when starting a task is the
            initial inertia or procrastination. Small rewards act as a form of
            positive reinforcement, creating a sense of purpose and satisfaction
            from the beginning. This increases the likelihood that a user will
            start the task and continue working through subsequent ones. Over
            time, this habit-forming process strengthens intrinsic motivation,
            which is crucial for tackling more complex or daunting projects. By
            associating positive emotions with task completion, users can build
            a steady momentum that makes larger goals less intimidating and more
            achievable.
          </p>

          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "24px",
              marginBottom: "10px",
              color: "#2C3E50",
            }}
          >
            Scientific Support
          </h3>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            Scientific research supports the effectiveness of small rewards in
            improving motivation and performance. Studies on behavior
            reinforcement highlight how immediate rewards enhance motivation by
            creating a clear connection between effort and gratification. A
            study by
            <em>Woolley and Fishbach (2017)</em> found that proximal rewards, or
            those received soon after task completion, can increase persistence
            in pursuing goals by enhancing task engagement. Similarly, research
            by <em>Ryan and Deci (2000)</em> on self-determination theory
            demonstrates how external rewards, when used correctly, can foster a
            sense of autonomy and competence, further driving task completion.
          </p>

          <footer
            style={{
              borderTop: "1px solid #BDC3C7",
              paddingTop: "15px",
              marginTop: "20px",
            }}
          >
            <h4
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "20px",
                marginBottom: "10px",
                color: "#2C3E50",
              }}
            >
              References
            </h4>
            <ul
              style={{
                fontSize: "16px",
                color: "#BDC3C7",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <li>
                Woolley, K., & Fishbach, A. (2017). Immediate Rewards Predict
                Adherence to Long-Term Goals.{" "}
                <em>Personality and Social Psychology Bulletin</em>, 43(2),
                151–162.
              </li>
              <li>
                Ryan, R. M., & Deci, E. L. (2000). Self-determination theory and
                the facilitation of intrinsic motivation, social development,
                and well-being. <em>American Psychologist</em>, 55(1), 68–78.
              </li>
            </ul>
          </footer>
        </div>
      </div>

      {/* Contact Section */}
      <div id="contact" className="section">
        <br></br>
        <br></br>
        <h3>Contact Us</h3>
        <br />
        <p>
          If you have any questions, feel free to reach out to us at
          info@purefocus.dev!
        </p>
        <br />
        <div className={`navbar-links ${menuOpen ? "open" : ""}`}>
          <br></br>
          <a href="#terms">Terms of Use</a>
          <a href="#privacy">Privacy Policy</a>
        </div>

        {/* Home Section */}
        {/* Home Section */}
        <div
          id="terms"
          style={{
            padding: "40px",
            fontFamily: "Roboto, sans-serif",
            color: "#2C3E50",
            lineHeight: "1.6",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <h3
            style={{
              fontSize: "28px",
              fontWeight: "700",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Terms of Use
          </h3>

          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            Welcome to Purefocus.dev. By accessing and using this website, you
            agree to comply with and be bound by the following terms and
            conditions:
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            1. Acceptance of Terms
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            By using this website, you accept these terms in full. If you
            disagree with any part of these terms, you must not use this
            website.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            2. Use of Website
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            You may not use this website in any way that causes damage to the
            website, impairs the availability or accessibility of the website,
            or in any way that is unlawful, fraudulent, or harmful.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            3. Intellectual Property
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            The content on this website, including text, images, and code, is
            the intellectual property of Purefocus.dev or its licensors and is
            protected by intellectual property laws. You may not reproduce,
            distribute, or otherwise use the content for any commercial purposes
            without permission.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            4. Limitation of Liability
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We strive to ensure that the information on this website is accurate
            and up-to-date, but we make no guarantees. We shall not be liable
            for any damages resulting from the use or inability to use this
            website.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            5. Privacy Policy
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            Your use of this website is also governed by our Privacy Policy,
            which explains how we collect, use, and protect your data. By using
            this website, you consent to the terms of our Privacy Policy.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            6. Modifications to Terms
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We reserve the right to modify these terms at any time. Changes will
            be effective immediately upon posting to the website. It is your
            responsibility to review these terms regularly.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            7. Governing Law
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            These terms shall be governed by and construed in accordance with
            the laws of Croatia, EU. Any disputes relating to these terms shall
            be subject to the exclusive jurisdiction of the courts of Osijek,
            Croatia.
          </p>

          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            If you have any questions about these Terms of Use, please contact
            us at info@purefocus.dev.
          </p>
        </div>

        {/* Privacy Policy Section */}
        <div
          id="privacy"
          style={{
            padding: "40px",
            fontFamily: "Roboto, sans-serif",
            color: "#2C3E50",
            lineHeight: "1.6",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <h3
            style={{
              fontSize: "28px",
              fontWeight: "700",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Privacy Policy
          </h3>

          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            This Privacy Policy explains how Purefocus.dev collects, uses, and
            protects your personal data when you use our website. We are
            committed to ensuring that your privacy is protected in accordance
            with applicable data protection laws, including the General Data
            Protection Regulation (GDPR).
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            1. Data Collection
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We do not collect personal data directly from users, but we use
            third-party services such as Google Analytics and Google Ads, which
            may collect certain information from your device, such as:
          </p>
          <ul
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
              listStyleType: "disc",
            }}
          >
            Your IP address<br></br> Browser type and version<br></br> Time zone
            settings and browser plug-in types<br></br> Operating system and
            platform <br></br>User behavior on our website
          </ul>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            2. Use of Google Analytics
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We use Google Analytics to track and report website traffic and
            improve our services. Google Analytics may collect data such as your
            IP address and user behavior. This data is anonymized, and we do not
            have access to personal data that could identify you directly.
            Google Analytics uses cookies to collect information. You can learn
            more about Google's privacy policies{" "}
            <a href="https://policies.google.com/privacy">here</a>.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            3. Use of Google Ads
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We use Google Ads to display advertisements on our website. Google
            Ads may collect information about your visits to our website and
            other websites to provide relevant advertisements. You can control
            personalized advertising in your Google Account settings. Learn more
            about Google Ads{" "}
            <a href="https://policies.google.com/privacy">here</a>.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            4. Cookies
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We use cookies to improve your experience on our website and to
            provide analytics data to third-party providers such as Google.
            Cookies are small files placed on your device that allow us to
            recognize and remember you. You can manage your cookie settings in
            your browser or opt-out of cookie tracking via the Google opt-out
            tool <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            5. Your Rights
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            Under GDPR, you have the following rights regarding your data:
          </p>
          <ul
            style={{
              marginLeft: "20px",
              marginBottom: "20px",
              listStyleType: "disc",
            }}
          >
            The right to access your data<br></br>
            The right to correct any inaccurate data<br></br>
            The right to request the deletion of your data<br></br>
            The right to restrict the processing of your data<br></br>
            The right to object to data processing<br></br>
          </ul>

          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            If you would like to exercise any of these rights, please contact us
            at info@purefocus.dev.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            6. Data Security
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We are committed to ensuring that your data is secure. We use
            industry-standard security measures to prevent unauthorized access,
            disclosure, or loss of your data.
          </p>

          <h4
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            7. Changes to this Privacy Policy
          </h4>
          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website.
            It is your responsibility to review this policy periodically.
          </p>

          <p
            style={{
              fontSize: "18px",
              color: "#7F8C8D",
              textAlign: "justify",
              marginBottom: "20px",
            }}
          >
            If you have any questions or concerns about this Privacy Policy,
            please contact us at info@purefocus.dev.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PomodoroApp;
