import logo from './logo.svg';
import './App.css';
import PomodoroApp from './components/PomodoroApp';

function App() {
  return (
    <div className="App">
      <PomodoroApp/>
    </div>
  );
}

export default App;
